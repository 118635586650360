import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

const BarChart = () => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        type: "bar",
        label: "New Users",
        data: [
          32391, 45390, 60027, 48900, 72345, 55314, 74108, 90304, 80210, 71309,
          65023, 83092,
        ],
        backgroundColor: "#034c3c",
        borderColor: "#034c3c",
        borderWidth: 2,
        order: 1,
      },
      {
        type: "line",
        label: "User Trends",
        data: [
          30000, 38000, 50000, 35000, 60000, 45000, 70000, 80000, 75000, 65000,
          60000, 80000,
        ],
        borderColor: "#279af1",
        backgroundColor: "#279af1",
        fill: false,
        borderWidth: 3,
        tension: 0.3, // Smoother curve for the line
        pointRadius: 0, // No points for a clean look
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    animation: {
      duration: 2500, 
      easing: "easeInOutQuad", // Smooth animation
    },
  };

  return (
    <div className="chart-container" style={{ height: "450px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
