import React from "react";
import "./App.css";
import BarChart from "./BarChart"; 

const App = () => {
  return (
    <div className="app-container">
      <Header />
      <Section id="about" title="Transforming Data, Unlocking Opportunity">
        <div className="flex-container">
          <div className="chart-box">
            <BarChart />
          </div>
        </div>
      </Section>
      <Section2 />
      <About />
      <Footer />
    </div>
  );
};

const Header = () => (
  <header className="header">
    <div className="logo">
      <img src={"/Header_Icon.png"} alt="Logo" className="logo-image" />
      <h1>SMITH CLOVE</h1>
    </div>
    <nav className="nav-links">
      <a href="#about">About</a>
      <a href="#resources">Resources</a>
      <a href="#meet-david">Meet David</a>
    </nav>
  </header>
);

const Section = ({ id, title, description, children }) => (
  <section id={id} className="section">
    <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
    {description && <p className="description">{description}</p>}
    {children}
  </section>
);

const Section2 = () => (
  <Section
    id="resources"
    title="All you need gathered in <em>one</em> place."
    description="">
    <div className="description-text">
      Download our free resources to help you make better decisions with data.
      These files are designed to help you get started with data analysis and
      visualization, and focus on the most important aspects of your business.
      <br />
      <br />
      This links to David's GitHub repo with the files. To download them as a
      ZIP folder, go to the repository, click on the "Code" button, and select
      "Download ZIP".
      <br />
      <br />
    </div>
    <div className="buttons-container">
      <Button
        label="LinkedIn"
        iconSrc="/icons-48.png"
        onClick={() =>
          handleButtonClick("https://github.com/SmithClove/linkedin/")
        }
      />
      <Button
        label="Salaries"
        iconSrc="/icons-48.png"
        onClick={() =>
          handleButtonClick("https://github.com/SmithClove/Salaries")
        }
      />
    </div>
  </Section>
);

const handleButtonClick = (url) => {
  try {
    if (url && typeof url === "string") {
      window.open(url, "_blank");
    } else {
      alert("Invalid URL");
    }
  } catch (error) {
    alert("Failed to open the link. Please try again.");
    console.error("Error opening the link:", error);
  }
};

const Button = ({ label, iconSrc, onClick }) => (
  <button onClick={onClick} className="button">
    <img src={iconSrc} alt="" className="button-icon" />
    <span className="button-label">{label}</span>
  </button>
);

const About = () => (
  <about id="meet-david" className="about">
    <div className="about-flex-container">
      <div className="about-image">
        <img
          src="/david_photo.png"
          alt="David"
          className="profile-image"
          style={{ width: "60%" }}
        />
      </div>
      <div className="about-description-box">
        <p>
          Smith Clove LLC is a small, veteran-owned business empowering
          organizations to unlock the power of their data.
          <br />
          <br />
          We educate and deliver strategies that optimize performance,
          streamline operations, and drive results across industries.
          <br />
          <br />
          Ready to turn your assets into actionable insights?
          <br />
          <br />
          Partner with us today and let's forge success together.
        </p>
      </div>

      <div className="about-description-text">
        <p>
          Smith Clove LLC is a small, veteran-owned business empowering
          organizations to unlock the power of their data.
          <br />
          <br />
          We educate and deliver strategies that optimize performance,
          streamline operations, and drive results across industries.
          <br />
          <br />
          Ready to turn your assets into actionable insights?
          <br />
          <br />
          Partner with us today and let's forge success together.
        </p>
      </div>
    </div>
  </about>
);

const Footer = () => (
  <footer id="footer" className="footer">
    <h3>Contact Us</h3>
    <p>
      Have a question or just want to say hi? Reach out to us – we're here to
      help!
    </p>

    <div className="contact-info">
      <a href="mailto:david@smithclove.net">david@smithclove.net</a>
      <span>+1 541 210 3100</span>
    </div>

    <div className="legal-info">
      <p>
        &copy; {new Date().getFullYear()} Smith Clove LLC. All rights reserved.
      </p>
      <p>
      </p>
    </div>
  </footer>
);

export default App;
